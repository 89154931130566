
class Search {
    constructor(container) {
        this.container = container;
        this.icon = this.container.find(".SearchIcon");
        this.form = this.container.find("form[name=search_form]");
        this.input_field = this.container.find("input[name=search]");
        this.search_results = this.container.find(".SearchResult");

        this.article_list = []
        this.fuse = null;

        this.loadArticleList();


    }

    initialize() {
        var self = this;
        this.icon.on('click', function (event) {
            self.container.toggleClass("active");
            self.form.toggleClass("active");

            $(".Logo").toggleClass("Searching");

            if (self.form.hasClass("active")) {
                self.input_field.focus();
            }
            else {
                self.input_field.blur();
            }
        });


        this.input_field.on('input', function (event) {
            console.log("Search search");
            console.log(self.form.serialize());
            self.search(self.form.serialize());
        });

        this.form.submit(function (event) {
            event.preventDefault();
            self.search(self.form.serialize());
        });
    }


    onLoadArticleList(result){
        this.article_list = result;

        var options = {
            shouldSort: true,
            threshold: 0.25,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
              "name", "tags"
          ]
          };
        this.fuse = new Fuse(this.article_list, options);
    }



    loadArticleList()
    {
        var self = this;

        $.ajax({
            url: '/get_article_list',
            type: 'POST',
            success: function (response) {
                self.onLoadArticleList(response)
            },
            error: function (error) {
                console.log("error");
                console.log(error);
            }
        });
    }

    showSearchResult(result) {
        var self = this;
        console.log("show result");

        $(this.search_results).empty();

        console.log("add class active");
        this.search_results.addClass("active");

        if (result.length == 0) {
        console.log("no results");
            self.search_results.append(self.createSearchResult("Found no results", ".", "", "", ""));
        }
        else {
        console.log("sort results");
            result.sort(function (lhs, rhs) {
                if (lhs.rank <= rhs.rank) {
                    return lhs.simple_rank <= rhs.simple_rank
                }
            });

        console.log("sappend results");
            jQuery.each(result, function (i, item) {
                self.search_results.append(self.createSearchResult(item.name, item.link, item.context, item.category, item.tags));
            });
        }
    }

    search(input) {
        var search_token = input.split("=")[1];

        console.log("Search token");
        console.log(search_token);

        if(search_token.length < 2){
            this.search_results.removeClass("active");
            $(this.search_results).empty();
            return;
        }

        search_token = search_token.replace("%20", " ");

        console.log("fuse");
        var result = this.fuse.search(search_token);
        console.log("result");
        console.log(result);

        this.showSearchResult(result);
    }

    createSearchResult(name, link, context, category, tag_list) {
        var tags = " "

        if (tag_list.length == 0) {
            tags += "None"
        }

        for (var i = 0; i < tag_list.length; ++i) {
            if (i == 0) {
                tags += tag_list[i]
            }
            else {
                tags += ", " + tag_list[i]
            }
        }

        return ("<div class='SearchResultItem'>"
            + "<a href='" + link + "'>"
            + "<div class='SearchResultDetails'>"
            + context + " - " + category
            + "</div>"
            + "<div class='SearchResultArticle'>"
            + name
            + "</div>"
            + "<div class='SearchResultTags'>"
            + "Tags: " + tags
            + "</div>"
            + "</a>"
            + "</div>");
    }
}

