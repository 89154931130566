
var is_manual_scroll = false;
var selected_article_id = "";
var current_article_id = "";
function readPath(){
    var path_parts = window.location.pathname.split("/");
    if(path_parts.length > 1)
    {
        var section_id = path_parts[1];
        // console.log("section: " + section_id);
        if(section_id != "")
        {
            $(".Menu #" + section_id).addClass("active");
        }
    }
    if(path_parts.length > 2)
    {
        var category_id = path_parts[2];
        if (category_id.length > 0) {
            $("#" + category_id).addClass("active");
        } else {
            console.log("ignore zero length category: " + category_id);
        }
    }
}

function updateAnchors()
{
    var previous_button = $(".ContentMenu .Item.active");

    if(current_article_id == "")
    {    
        console.log("scroll to 0,0");
        window.scrollTo(0,0);
        previous_button.removeClass("active");
        return;
    }

    if(is_manual_scroll)
    {
        var article_offset = $("#"+current_article_id).offset();
        console.log("scroll to " + article_offset.left + "," + article_offset.top);
        window.scrollTo(article_offset.left, article_offset.top);
    }
    
    var current_button = $('.ContentMenu .Item[href="#' + current_article_id + '"]');
    if(current_button.length)
    {
        current_button.addClass("active");
        previous_button.removeClass("active");
    }
}

function onHashChange()
{
    selected_article_id = window.location.hash.replace("#","");
    current_article_id = selected_article_id;
    updateAnchors();
}

$(window).on('hashchange',function()
{ 
    is_manual_scroll = false;
    onHashChange();
});

function setupScroll()
{
    stopScrollPropagation($(".MainMenuContextMenu"));
    stopScrollPropagation($(".MainMenuCategories"));
    stopScrollPropagation($(".ContentMenu"));
    $(document).scroll(function () {
        var is_at_bottom = $(window).scrollTop() + $(window).height() == $(document).height();
        if(is_at_bottom)
        {
            is_manual_scroll = false;
            current_article_id = $('.Article').last().attr('id');
            updateAnchors();
            return;
        }
        $('.Article').each(function () {
            var top = window.pageYOffset;
            var distance = top - $(this).offset().top;
            var article_id = $(this).attr('id');
            // 30 is an arbitrary padding choice
            if (distance < 30 && distance > -30 && current_article_id != article_id) 
            {
                is_manual_scroll = false;
                current_article_id = article_id;
                // just update the anchors, we don't want to set the window.location.hash
                // because that will also add it to the history
                updateAnchors();
            }
        });
    });
}

function activateCategory(category){
    $(category).addClass("active");

    $(".MainMenuArticles.active").removeClass("active");
    $(".MainMenuArticles[category=" + $(category).attr("category") + "]").addClass("active");
 
}

function hoverContext(){
    return function () {
        var previous = $(".MainMenuContext.active");
        $(previous).removeClass("active")
        $(this).addClass("active");

        $(".MainMenuCategories.active").removeClass("active");
        $($(".MainMenuCategories")[$(this).index()]).addClass("active");
        
        $(".MainMenuCategories.active .MainMenuCategory.active").removeClass("active");
        activateCategory($(".MainMenuCategories.active .MainMenuCategory")[0]);
    };
}

function hoverCategory(){
    return function () {
        $(".MainMenuCategory.active").removeClass("active")
        $(this).addClass("active");
        activateCategory(this);
    };
}

function preventScroll(element, class_name) {
    element.each(function(){
        this.addEventListener("wheel", function(event) {
            var prevent = true;
            if(class_name)
            {
                if(!$(this).hasClass(class_name))
                {
                    prevent = false;
                }
            }
            if(prevent)
            {
                event.preventDefault();
                event.stopPropagation();
            }
        }, {"passive": false});
    });
}

function stopScrollPropagation(element) {
    element.each(function(){
        this.addEventListener("wheel", function(event) {
            var height = $(this).height() + parseFloat(getComputedStyle(this).marginBottom,0);
            var scrollHeight = $(this).get(0).scrollHeight;
            if(Math.round(scrollHeight - height) < 2)
            {
                return;
            }
            var delta = event.deltaY;
            var is_at_top = this.scrollTop === 0;
            var is_at_bottom = Math.round((scrollHeight - height) - this.scrollTop) < 2;
            if((is_at_bottom && delta > 0) || 
               (is_at_top && delta < 0 )){
                event.preventDefault();
            }
        }, {"passive": false});
    });
}

function initializeDropDownMenu()
{
    var toggle_menu_button = $(".NavigationMenuToggle");
    toggle_menu_button.on("click", function(event) {

    var drop_down_wrapper = $(".MainMenuWrapper"); 
    
    var target = $(event.target);
        if(target.hasClass("NavigationMenuToggle") ||
           target.hasClass("ThreeHorizontalBars") ||
           target.hasClass("MainMenuWrapper"))
        {
            toggle_menu_button.toggleClass("active");

            drop_down_wrapper.toggleClass("active");
            drop_down_wrapper.toggleClass("visible");
        }
    });

    var context_menu_list = $(".MainMenuContext");
    $(context_menu_list).hover(hoverContext());

    var category_menu_list = $(".MainMenuCategory");
    $(category_menu_list).hover(hoverCategory());
}    


function initializeBreadCrumbs()
{
    var bread_crumbs = $(".BreadCrumbs");
    bread_crumbs.click(function(event) {
        var has_class = $(this).hasClass("Active");
        bread_crumbs.removeClass("Active");

        if(has_class == false){
            $(this).addClass("Active");
        }

        console.log("Here");
    });

    bread_crumbs.hover(function(event) {
        bread_crumbs.removeClass("Active");

    });

    var menu_item = $(".BreadCrumbsHoverMenuItem");
    menu_item.click(function(event) {
        bread_crumbs.removeClass("Active");
    });
}

function setupChrome(){
    $('html').addClass('Chrome');
}

function setupFirefox(){
    $('html').addClass('Firefox');
}

function setupSafari(){
    $('html').addClass('Safari');
}

function setupInternetExplorer(){
    $('html').addClass('InternetExplorer');
    $('img').each(function(){
        this.onerror = null;
        this.src = '';
        this.alt = '';
    });
}

function setupEdge(){
    $('html').addClass('Edge');
}

function initializeByBrowser(){
    var ua = navigator.userAgent.toLowerCase();
    console.log("User Agent: " + ua);
    if (ua.indexOf("chrome/") != -1) {
        setupChrome();
    }

    if (ua.indexOf("firefox/") != -1) {
        setupFirefox();
    }

    if (ua.indexOf("safari/") != -1) {
        setupSafari();
    }

    if (ua.indexOf("msie ") != -1 || //IE 10
        ua.indexOf("trident/") != -1) { //IE 11
        setupInternetExplorer();
    }

    if (ua.indexOf("edge/") != -1) {
        setupEdge();
    }    
}


$( document ).ready(function() 
{
    initializeByBrowser();

    $(".SlideShow").each(function(index)
    {
            var slide_show = new SlideShow($(this));
            slide_show.initialize();
    });

    initializeDropDownMenu();
    initializeBreadCrumbs();

    readPath();
    
    selected_article_id = "";
    current_article_id = "";
    setupScroll();
    
    is_manual_scroll = true;
    onHashChange();
    console.debug("ready");

    var search = new Search($(".Search"));
    search.initialize();
});


