class SlideShow{
    constructor(container) {
        console.log(container);
        this.container = container;
        this.slides = container.find(".Slide");
        this.selectors = container.find(".selector");
        this.next_selector = container.find(".direction-selector.Next");
        this.previous_selector = container.find(".direction-selector.Previous");
        this.is_automatic = false;
        this.is_just_enabled = false;
        this.current_index = 0;
    }

    initialize() {
        // We don't do the slide show if there are no slides
        if (this.slides.length == 0) {
            return;
        }

        
        preventScroll(this.container, "Fullscreen");
        this.selectors.click(this.selectSlide());
        this.slides.click(this.enableAutomatic());
        this.next_selector.click(this.selectNext());
        this.previous_selector.click(this.selectPrevious());

        this.slides.first().show();
        this.is_automatic = true;
        var self = this;
        if (this.slides.length > 0) {
            setInterval(function () { self.nextSlide(); }, 4500);
        }
    }

    toString() {
        console.log('<SlideShow> name: ' + this.name);
    }


    nextSlide() {
        if(this.is_automatic){
            this.showSlide(this.current_index + 1);
        }
    }

    showSlide(n) {
        if(n >= this.slides.length)
        {
            n = 0;
        }

        var current = this.container.find(".Slide.active");
        // do nothing if the current slide is already the one we want
        if(n == this.slides.index(current)){
            return;
        }

        var current_selector = this.container.find(".selector.current")

        $(this.selectors[n]).addClass("current");
        $(this.slides[n]).addClass("active");




        current.fadeTo(1000, 0);
        $(this.slides[n]).fadeTo(1000, 1);

        current.removeClass("active");
        current_selector.removeClass("current");
        

        this.current_index = n;
    }

    enableAutomatic() {
        var self = this;
        return function () {
            if(self.container.hasClass("Home") == false)
            {
                self.container.toggleClass("Fullscreen");
            }
            self.is_automatic = true;
            var current = self.container.find(".Slide.active");
            var index = self.slides.index(current) + 1;
            self.showSlide(index);
        }
    }

    selectNext() {
        var self = this;
        return function () {
            self.is_automatic = false;
            var current = self.container.find(".Slide.active");
            var index = self.slides.index(current) + 1;
            self.showSlide(index);
        }
    }

    selectPrevious() {
        var self = this;
        return function () {
            self.is_automatic = false;
            var current = self.container.find(".Slide.active");
            var index = self.slides.index(current) - 1;
            self.showSlide(index);
        }
    }

    selectSlide() {
        var self = this;
        return function () {
            var index = $(self.selectors).index(this);
            self.is_automatic = false;
            self.showSlide(index);
        }
    }
}

